import React from "react"
import Layout from "../components/layout"
import Middle from "../components/Middle"
import imageUrl from "../img/aikido20.jpg"
import imageStara from "../img/aikido-celje-stara.jpg"
import "../../static/klub.css"
const klub = () => {
  return (
    <Layout>
      <Middle title={"klub"} />
      <div class="akc-klub">
        <h1>Od aikido sekcije do aikido klubaa</h1>
        <p>
          Današnji Aikido klub Celje je začel svoje delovanje kot sekcija Judo
          kluba Sankaku v jeseni 1997 v prostorih Sankaku kluba na Lopati. V
          začetku v majhni telovadnici in kasneje v novi, veliki dvorani na isti
          lokaciji in to pod vodstvom Iztoka Praprotnika. Velike zasluge za
          obstoj in razvoj kluba ima poleg Iztoka tudi Marjan Fabjan, ki je
          pomagal, tako s prostorom za treninge kot s samo promocijo sekcije.
        </p>
        <br />
        <div
          class="klub-img"
          style={{ backgroundImage: `url(${imageUrl})` }}
        ></div>
        <p>
          Sekcije, ki je v letu 2000 prerasla v samostojen klub in je v času
          svojega delovanja vzgojila kar nekaj aikidok. V začetku smo trenirali
          klasični aikido (ten-do smer) pod vodstvom Kenji Shimiza 8.DAN.
          Kasneje nadaljevali pod mojstrom Klausom Krugerjem 5.DAN in pod nemško
          aikido federacijo dobili tudi prve mojstre (poleg Iztoka). Redno smo
          se udeleževali izobraževanj in seminarjev po Evropi in med drugim smo
          navezali stike z Ljubomirjem Vračarevičem, ki je že takrat učil in
          razvijal realni aikido. Začeli smo se družiti, se udeleževati
          seminarjev in pri tem ugotovili, da je to aikido, ki ga želimo živeti,
          trenirati in učiti.
        </p>
        <p>
          V realnem aikidu smo našli tisto, kar nam je manjkalo v tradicionalnem
          aikidu. Preimenovali smo se v Klub realnega aikida Celje in se z
          ostalimi istomislečimi povezali v Zvezo Klubov realnega aikida
          Slovenije, ki jo sestavljajo klubi iz Maribora, Ljubljane, Celja,
          Ptuja, Slovenskih Konjic in Murske Sobote. Po letih treniranja, smo se
          zaradi učenja različnih tehnik in veščin Aikida, ki jih prakticiramo
          na naših treningih, ne samo tehnik in veščin realnega Aikida mojstra
          Ljubomirja Vračareviæ-a, odločili in se preimenovali nazaj v Aikido
          klub Celje, in tako v ime našega kluba povrnili tisto nevtralnost in
          širino s tem pa tudi bogastvo Aikida, ki ga vskodnevno izražamo pri
          našem delovanju. Aikido klub Celje (program vodi Uroš Jezernik) ima že
          tretje leto sklenjeno pogodbo z Ministrstvom za pravosodje za
          usposabljanje in izvajanje treningov samoobrambe paznikov v zaporih v
          Celju. Naš klub danes šteje okoli 50 članov. Skozi naše vrste je šlo
          ogromno študentov, ki so po začetkih v našem klubu, s treningi
          nadaljevali v naših klubih v Ljubljani ali Mariboru, odvisno od
          lokacije študija.
        </p>
        <div
          class="klub-img"
          style={{ backgroundImage: `url(${imageStara})` }}
        ></div>
        <p>
          Imamo kar nekaj starejših članov od katerih izstopa mojstrski kandidat
          Bine Počivavšek. Vsi, ki so šli skozi naše vrste so pomembno
          prispevali k rasti in razvoju realnega aikida, eni tako drugi drugače.
          Trenerji se trudimo, da je vzdušje v klubu sproščeno in da se člani v
          njem dobro počutijo. Pohvalimo se lahko z res pestro sestavo članov in
          pestrim dogajanjem v klubu in to ne samo v telovadnici, kar je
          razvidno iz naših galerij. Ste pa v naši sredini vsi dobrodošli,
          starejši, mlajši, ženske in dekleta. Pridite, poglejte in se po enem
          ali dveh treningih odločite ali boste poskusili. Mi smo in smo se v
          tem našli. V aikidu in v družbi.
        </p>
      </div>
    </Layout>
  )
}

export default klub
